
import { PortalRequest as request } from "@/utils/request";


// 广告
export const getAdvertiseList = (params) => {
    return request({
        url: "/res-tenant-ad/page-list",
        method: "post",
        data: params,
    });
};
