<template>
  <div class="hly-home-banner">
    <a-carousel autoplay :beforeChange="bannerafterChange">
      <div
          v-for="(el, index) in banner"
          :key="index"
          class="hly-home-banner-list"
          style="height: 320px"
      >
<!--        :style=homeStyle-->
<!--        :defaultImg="require('@/assets/img/kctp.png')"-->
<!--        <v-img
            :attachment-id="el.adAttmId"
        />-->
        <img :src="el.img" alt="">
      </div>
    </a-carousel>
<!--    <div  :class="{'solution':textinfo.type==='solution',
                  'replace':textinfo.type==='replace',
                  'list':textinfo.type==='list',
                  'activity':textinfo.type==='activity',
    }"

          :style="{'opacity':bannerInfo?1:0,'zIndex':bannerInfo?'2':'-2'}">-->
      <div :class="textinfo.type==='list'? 'list':'replace'"
            :style="{'opacity':bannerInfo?1:0,'zIndex':bannerInfo?'2':'-2'}">
      <div class="banner-text">
        <span>{{textinfo.title}}</span>
        <p>{{textinfo.text}}</p>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getTenantAds} from "@/api/common";
import VImg from "@/components/VImg";

export default {
  name: "BannerSwiper",
  components: { VImg },
  props:{
    banner:{
      type:Array,
      default:function (){
        return [
          { adAttmId: "",
            validStatus: "1",}
        ]
      }
    },
    textinfo:{
      type:Object,
    }
  },
  data() {
    return {
      adList: [],
      searchName:'',
      bannerInfo:true,
      advertise:[

      ],
      homeStyle:`height:${this.textinfo.height}px`
    };
  },
  computed: {
    ...mapGetters(["tenant"]),
    list() {
      const { adList } = this;
      const arr = [...adList];
      for (let i = 0; i < 1 - adList.length; i++) {
        arr.push({});
      }
      console.log(arr,'arr')
      return arr;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {

    toadvertise(url){
      if(url){
        window.open(url)
      }
    },


    bannerafterChange(from, to){
      if(to == 0){
        this.bannerInfo = true
      }else{
        this.bannerInfo = false
      }
    },


    loadData() {
      const { tenant } = this;
      getTenantAds(tenant.id).then((list) => {
        this.adList = list;
      });
    },
    onSearch(value) {
      if(!value){
        this.$message.warning('请输入搜索关键词！');
        return
      }
      this.$router.push(`/searchInfo?val=${value}`);
    },
  },

}
</script>


<style lang="scss" scoped>
.hly-home-banner {
  position: relative;
  height: 320px;
  .hly-home-banner-list {
    display: block !important;
    position: relative;
    z-index: 1;
    overflow: hidden;
    > img {
      position: absolute;
      height: 320px;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
    }

  }

  .replace {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 650px;
    margin-left: -640px;
    padding-top: 119px;
    transition: all .3s;

  /*  position: absolute;
    left: 320px;
    top: 119px;
    z-index: 10;*/


    .banner-text {
      > span {
        line-height: 22px;
        font-size: 38px;
        display: block;
        font-weight: bold;
        color: #262626;
        margin-bottom: 24px;
      }

      > p {
        font-size: 18px;
        font-weight: 400;
        color: #262626;
        line-height: 22px;
      }
    }
  }


  .list {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 650px;
    margin-left: -600px;
    padding-top: 119px;
    transition: all .3s;
    .banner-text {
      > span {
        line-height: 22px;
        font-size: 38px;
        display: block;
        font-weight: bold;
        color: #262626;
        margin-bottom: 24px;
      }

      > p {
        font-size: 18px;
        font-weight: 500;
        color: #262626;
        line-height: 22px;
      }
    }
  }

   /*.replace {
    width: 1280px;
    position: absolute;
    top: 80px;
    left: 50%;
    margin-left: -640px;
    text-align: center;
    transition: all .3s;
     .banner-text{
       > span {
         line-height: 48px;
         font-size: 48px;
         display: block;
         color: #ffffff;
         margin-bottom: 24px;
       }
       > p {
         display: flex;
         align-items: center;
         justify-content: center;
         color: #fff;
         line-height: 13px;
         font-weight: 300;
       }
     }

  }*/



  .activity{
    width: 1280px;
    position: absolute;
    top: 103px;
    left: 50%;
    margin-left: -640px;
    transition: all .3s;
    .banner-text{
      > span {
        display: block;
        line-height: 47px;
        font-size: 48px;
        margin-bottom: 24px;
        font-weight: 300;
      }
      > p {
        display: block;
        line-height: 12px;
      }

    }

  }


  .solution {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 650px;
    margin-left: -640px;
    padding-top: 100px;
    transition: all .3s;
    .banner-text{
      > span {
        line-height: 64px;
        font-size: 48px;
        display: block;
        margin-bottom: 14px;
      }
      > p {
        display: flex;
        align-items: center;
        color: #8c8c8c;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
}
</style>
<style>
.hly-home-banner-content input {
  font-size: 16px;
  border: 2px solid #ea4243 !important;
  border-color: #ea4243 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.hly-home-banner-content .ant-input-search-button {
  padding: 0 24px;
  background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%) !important;
  font-size: 16px;
  color: #fff !important;
  border: none;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
